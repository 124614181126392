.yellow-border {
  border-width: 2px;
}
.button {
  font-size: 14px !important;
}
.ant-btn-text {
  color: var(--color-gray);
}
.button.ant-btn-default.ant-btn-dangerous {
  background: #F0F0F0;
  border-color: #F0F0F0;
}
.button.ant-btn-default.ant-btn-dangerous span {
  color: #F04134;
}
