.attachments-list .files-download-button {
  height: unset;
  padding: 0;
}
.attachments-list {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background: #fff;
  margin-top: 12px;
  border-radius: 8px;
  margin: 16px;
}
.attachment-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.files {
  max-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px;
  overflow: auto;
  padding-right: 10px;
}
.files-download-button .ant-btn-icon .anticon {
  font-size: 24px !important;
}
.all-files-button {
  border-top: 1px solid #d7d7d7;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 24px;
}
.all-files-button:hover {
  color: var(--color-primary) !important;
}
.all-files-button.ant-btn-disabled:hover,
.all-files-button.ant-btn-text:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.all-files-button svg {
  font-size: 24px;
}
