@media (max-width: 768px) {
  .header-logo {
    height: 9px !important;
  }
  .auth-header-logo {
    height: auto !important;
  }
  .auth-logo-mobile-wrapper {
    position: absolute;
    width: 100%;
    padding: 30px 0;
    text-align: center;
  }
}
