.email-form-container {
  max-width: 470px;
}
.email-form-container .ant-form-item {
  margin-bottom: 16px;
}
.email-form .email-form-textarea {
  min-height: 80px;
  border: none;
  border-radius: unset;
  padding-left: 0;
}
.email-form-textarea::placeholder {
  color: var(--color-gray);
}
.email-form-input {
  border: none;
  border-radius: unset;
  position: relative;
}
.email-form-container .anticon-close {
  font-size: 7px;
  color: var(--color-gray);
}
.email-form-input .ant-select-multiple .ant-select-selection-item {
  border-radius: 8px;
  background-color: #f6f8ff;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray);
}
.email-form-input
  .ant-col.ant-form-item-control
  > div:not(.ant-form-item-control-input) {
  display: none !important;
}
.email-form-input:focus,
.email-form-textarea:focus {
  border-color: unset !important;
  box-shadow: none !important;
}
.email-form .ant-form-item .ant-form-item-label > label {
  color: var(--color-gray);
}
.email-form .ant-form-item {
  word-break: break-word;
  margin-bottom: 16px;
}
.email-form .creatable-select-prefix-label {
  position: absolute;
  z-index: 11;
  top: 8px;
  left: 16px;
  color: var(--color-gray);
  font-weight: 400;
}
.email-form .ant-divider-horizontal,
.email-form .ant-form-item-explain {
  margin: 0;
}
.required-divider {
  background-color: #b3261e;
}
.files-container {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background: #fff;
  /* padding-right: 16px; */
  margin-top: 12px;
}
.files {
  max-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px;
  overflow: auto;
  padding-right: 10px;
}
.select-all-checkbox > span {
  padding-inline-end: 0;
}
