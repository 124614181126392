.revision-tag {
  padding: 3px 14px;
  border-radius: 8px;
  background-color: transparent;
}
.status-wrapper {
  padding: 10px 14px;
}
table {
  background-color: white;
}
.filters-wrapper {
  padding: 8;
  background-color: "#fff";
  border-radius: 4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.activeItem {
  background-color: #ecf0ff;
}
.anticon-filter > svg {
  fill: #ffffff;
}
.dropdown-wrapper {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.dropdown-button-wrapper {
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-button,
.dropdown-button.ant-btn.ant-btn-sm {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  padding: 14px 24px;
  background-color: #fff;
}
.dropdown-menu.ant-dropdown-menu {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  padding: 12px 0;
}
.dropdown-wrapper li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: 0;
}
.collaspsable-list-item-wrapper {
  width: 100%;
}

.project-details-wrapper {
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
}
.project-details-header-wrapper {
  margin-bottom: 36px;
  margin-top: 8px;
}
.project-details-header-wrapper .button {
  height: unset;
}
.statuses-container {
  display: flex;
  gap: 24px;
}
.projects-container {
  position: relative;
}
.create-draft-button {
  position: absolute;
  z-index: 11;
  right: 0;
  top: -66px;
}
.filter-badge-wrapper {
  position: relative;
}
.filter-badge {
  position: absolute;
  top: -6px;
  left: -7px;
}
.filter-badge span.ant-badge-status-dot {
  height: 8px !important;
  width: 8px !important;
}
.edit-action-icon-wrapper {
  width: 30px;
}
.solar-details-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
  border-bottom: 2px solid var(--color-primary) !important;
}
.solar-details-container .card-container {
  box-shadow: unset !important;
  border: none;
  border-radius: 0;
  border-bottom: unset !important;
}
.solar-details-container .solar-steps {
  background-color: #fff;
  padding: 16px 74px;
}
.project-container .page-title {
  margin-bottom: 0;
}
.projects-details-buttons-group label.ant-radio-button-wrapper:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.projects-details-buttons-group .buttons-group-item {
  border: 1px solid transparent;
  color: var(--color-gray);
}
.buttons-group-item.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
/*code dublication contractors/styles.css */
.scrollable-container-projects {
  height: calc(100vh - 335px);
  overflow: auto;
}
.scrollable-container-revisions {
  height: calc(100vh - 390px);
  overflow: auto;
}
.scrollable-container-revisions .ant-collapse-header,
.scrollable-container-revisions .ant-collapse-content-box {
  padding: 0 !important;
}
/* Drawing */
.drawing-container {
  max-width: 400px;
  margin: 0 auto;
}
.drawing-container .info-field-container {
  padding-top: 0;
}
.drawing-empty {
  padding: 62px;
  text-align: center;
}
.drawing-empty .ant-card-meta-description {
  color: #222 !important;
}
/* Revisions */
.revision-container {
  max-width: 400px;
  margin: 0 auto;
}
.list-item-wrapper.ant-list-item,
.project-list-item-content-wrapper.ant-list-item {
  padding: 4px 0;
}
.revisions-filters-container {
  max-width: fit-content;
  margin-left: auto;
  margin-bottom: 32px;
}
.revisions-filters.filters-container {
  inset: 210px 52px auto auto !important;
}
.revisions-list-item {
  padding: 20px 24px;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 8px;
  background-color: #fff;
}
.revisions-list-header {
  background-color: #49576b;
  color: #fff;
  font-weight: 500;
  margin-bottom: 12px;
}
.scrollable-container {
  /* height: calc(100vh - 200px); */
  overflow: auto;
}
@media (max-width: 768px) {
  .dropdown-button,
  .dropdown-button.ant-btn.ant-btn-icon-only {
    width: 72px;
  }
  .project-details-header-wrapper {
    flex-direction: column-reverse;
    margin-bottom: 24px;
  }
  .project-details-header {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #d7d7d7;
  }
  .project-details-header-buttons > .button {
    flex: 1;
    height: 40px;
  }
  .projects-details-buttons-group {
    display: flex;
    justify-content: center;
  }
  .projects-details-buttons-group > label {
    text-align: center;
  }
  .projects-details-buttons-group > label:first-child {
    width: 100%;
  }
  .statuses-container {
    gap: 14px;
  }
  .statuses-container .statuses-wrapper {
    flex: 1;
    justify-content: center;
  }
  .details {
    text-align: center;
  }
  .revisions-list-item {
    padding: 14px 24px;
  }
  .revisions-filters-container {
    margin-bottom: 12px;
    margin-left: 0;
  }
  .solar-details-container .solar-steps {
    padding: 16px;
  }
}
