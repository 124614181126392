.range-picker-popup * {
  font-family: "Roboto", sans-serif;
  font-weight: 400 !important;
}
.range-picker-popup .ant-picker-cell:not(.ant-picker-cell-in-view) {
  color: var(--color-gray) !important;
}
.range-picker-popup .ant-picker-cell-today {
  color: var(--color-primary);
}
.range-picker-popup .ant-picker-header {
  border-bottom: none;
  gap: 0;
  padding: 8px 24px;
}
.range-picker-popup .ant-picker-panels:first-child .ant-picker-body {
  padding: 8px 16px 16px 0;
}
.range-picker-popup .ant-picker-panels:last-child .ant-picker-body {
  padding: 8px 0 16px 16px;
}
.range-picker-popup .ant-picker-cell-inner,
.range-picker-popup .ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 2px !important;
}
.range-picker-popup thead th {
  font-size: 16px;
}
.range-picker {
  max-width: 232px !important;
  padding: 4px 16px;
}
.range-picker input::placeholder {
  color: var(--color-gray) !important;
}
.range-picker svg {
  fill: var(--color-gray);
}
.span.ant-picker-clear,
.ant-picker-range:hover .ant-picker-clear {
  opacity: 0;
}
.range-picker .ant-picker-suffix svg {
  font-size: 16px;
}
.ant-picker-dropdown .ant-picker-range-wrapper {
  justify-content: center;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::before,
.ant-picker-super-next-icon::after {
  width: 7px !important;
  height: 7px !important;
  color: var(--color-gray);
}
