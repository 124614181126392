.auth-container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.auth-container > div {
  width: 100%;
  padding: 30px 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.content-divider {
  height: 594px;
  margin: 0;
}

.auth-form-title {
  font-size: 24px;
  font-weight: 500;
  color: #222;
  margin-bottom: 32px;
  text-align: center;
}

.page-layout {
  height: 100vh;
  background-color: #272534;
  padding: 20px 20px 20px 0;
}

.page-content-container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.page-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

aside.ant-layout-sider.ant-layout-sider-dark.page-sider {
  margin-top: 20px;
  background: #272534;
}

.page-layout-content {
  padding: 32px;
  margin: 0;
  background-color: #f7f7f7;
  overflow: auto;
}

.menu-logo-wrapper {
  margin-bottom: 40px;
  padding-left: 30px;
  background-color: #272534;
}

.menu-logo-wrapper .header-logo {
  height: 14px;
}

@media (max-width: 768px) {
  .page-content-container {
    border-radius: unset;
  }

  .page-layout {
    padding: 0 0 50px;
    background-color: #f7f7f7;
    height: auto;
  }

  .page-layout-content {
    padding: 20px;
  }

  .auth-form-title {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .wrapper {
    padding: 15px;
    min-width: unset;
  }
}
