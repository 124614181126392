.modal .modal-custom-footer {
  justify-content: center;
  margin-top: 24px;
}
.ant-modal.modal {
  width: unset !important;
  max-width: 400px;
  margin: 20px auto;
}
.custom-modal.ant-modal {
  width: 100% !important;
  margin: 0;
  max-width: unset;
  top: unset;
  background-color: #fff;
}
.custom-modal.ant-modal .modal-header {
  padding: 20px 20px 16px;
  border: 1px solid #d7d7d7;
}
.custom-modal.ant-modal .ant-modal-body {
  height: 100%;
  width: 100%;
}
.custom-modal.ant-modal .ant-modal-close {
  display: none;
}
.custom-modal.ant-modal .ant-modal-content {
  background: #fff;
}
.custom-modal.ant-modal .modal-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-modal.ant-modal .range-picker,
.custom-modal.ant-modal .ant-modal-footer {
  width: 100%;
}
.ant-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: #fff;
  text-align: center;
}
.ant-modal-title {
  background: #f2f6fa;
}
.ant-modal .ant-modal-footer {
  display: flex;
  gap: 12px;
  margin-top: 24px;
  flex-direction: row-reverse;
}
.ant-modal .modal-custom-footer .ant-form-item {
  margin-bottom: 0;
}
.ant-modal .ant-modal-close {
  top: 8px;
  inset-inline-end: 8px;
}

@media (max-width: 768px) {
  .custom-modal.ant-modal .ant-modal-content {
    padding: 20px;
    height: calc(100vh - 54px - 60px);
  }
  .modal-custom-footer {
    flex-direction: column;
  }
  /* new modal */
  .custom-modal.ant-modal {
    height: calc(100vh - 54px);
    top: 54px;
    pointer-events: auto;
  }
}
