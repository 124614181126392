.drawer {
  max-height: calc(100vh - 63px);
}
.ant-drawer-content-wrapper {
  margin: 54px;
}
.drawer.ant-drawer-content {
  border-radius: 8px;
}
.drawer .ant-drawer-header {
  background-color: #f1f4ff;
  border-bottom: 1px solid #d7d7d7;
  padding: 14px 24px;
}
.drawer .ant-drawer-header .ant-drawer-title {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
}
.drawer .ant-drawer-body > div {
  width: 400px;
}
.ant-drawer-body:not(.menu-drawer .ant-drawer-body) {
  background-color: #f7f7f7;
  padding: 32px 24px;
}
@media (max-width: 768px) {
  .ant-drawer-content-wrapper {
    margin: 0;
  }
  .drawer .ant-drawer-body > div {
    width: 100%;
  }
  .ant-drawer-body:not(.menu-drawer .ant-drawer-body) {
    padding: 32px 20px;
  }
}
