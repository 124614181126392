:root {
  --margin-extra-large: 48px;
  --margin-xxxl: 32px;
  --margin-xxl: 28px;
  --margin-xl: 24px;
  --margin-lg: 18px;
  --margin-md: 16px;
  --margin-sm: 14px;
  --margin-xs: 12px;
  --margin-xxs: 8px;
  --margin-xxxs: 4px;
  --padding-extra-large: 48px;
  --padding-xxxl: 32px;
  --padding-xxl: 28px;
  --padding-xl: 24px;
  --padding-lg: 18px;
  --padding-md: 16px;
  --padding-sm: 14px;
  --padding-xs: 12px;
  --padding-xxs: 8px;
  --padding-xxxs: 4px;
}
/* margin */
.margin-extra-large {
  margin: var(--margin-extra-large);
}

.margin-xxxl {
  margin: var(--margin-xxxl);
}

.margin-xxl {
  margin: var(--margin-xxl);
}

.margin-xl {
  margin: var(--margin-xl);
}

.margin-lg {
  margin: var(--margin-lg);
}

.margin-md {
  margin: var(--margin-md);
}

.margin-sm {
  margin: var(--margin-sm);
}

.margin-xs {
  margin: var(--margin-xs);
}

.margin-xxs {
  margin: var(--margin-xxs);
}
.margin-xxxs {
  margin: var(--margin-xxxs);
}

/* margin-top */
.margin-top-extra-large {
  margin-top: var(--margin-extra-large);
}

.margin-top-xxxl {
  margin-top: var(--margin-xxxl);
}

.margin-top-xxl {
  margin-top: var(--margin-xxl);
}

.margin-top-xl {
  margin-top: var(--margin-xl);
}

.margin-top-lg {
  margin-top: var(--margin-lg);
}

.margin-top-md {
  margin-top: var(--margin-md);
}

.margin-top-sm {
  margin-top: var(--margin-sm);
}

.margin-top-xs {
  margin-top: var(--margin-xs);
}

.margin-top-xxs {
  margin-top: var(--margin-xxs);
}
.margin-top-xxxs {
  margin-top: var(--margin-xxxs);
}

/* margin-bottom */
.margin-bottom-extra-large {
  margin-bottom: var(--margin-extra-large);
}

.margin-bottom-xxxl {
  margin-bottom: var(--margin-xxxl);
}

.margin-bottom-xxl {
  margin-bottom: var(--margin-xxl);
}

.margin-bottom-xl {
  margin-bottom: var(--margin-xl);
}

.margin-bottom-lg {
  margin-bottom: var(--margin-lg);
}

.margin-bottom-md {
  margin-bottom: var(--margin-md);
}

.margin-bottom-sm {
  margin-bottom: var(--margin-sm);
}

.margin-bottom-xs {
  margin-bottom: var(--margin-xs);
}

.margin-bottom-xxs {
  margin-bottom: var(--margin-xxs);
}
.margin-bottom-xxxs {
  margin-bottom: var(--margin-xxxs);
}

/* margin-left */
.margin-left-extra-large {
  margin-left: var(--margin-extra-large);
}

.margin-left-xxxl {
  margin-left: var(--margin-xxxl);
}

.margin-left-xxl {
  margin-left: var(--margin-xxl);
}

.margin-left-xl {
  margin-left: var(--margin-xl);
}

.margin-left-lg {
  margin-left: var(--margin-lg);
}

.margin-left-md {
  margin-left: var(--margin-md);
}

.margin-left-sm {
  margin-left: var(--margin-sm);
}

.margin-left-xs {
  margin-left: var(--margin-xs);
}

.margin-left-xxs {
  margin-left: var(--margin-xxs);
}
.margin-left-xxxs {
  margin-left: var(--margin-xxxs);
}

/* margin-right */
.margin-right-extra-large {
  margin-right: var(--margin-extra-large);
}

.margin-right-xxxl {
  margin-right: var(--margin-xxxl);
}

.margin-right-xxl {
  margin-right: var(--margin-xxl);
}

.margin-right-xl {
  margin-right: var(--margin-xl);
}

.margin-right-lg {
  margin-right: var(--margin-lg);
}

.margin-right-md {
  margin-right: var(--margin-md);
}

.margin-right-sm {
  margin-right: var(--margin-sm);
}

.margin-right-xs {
  margin-right: var(--margin-xs);
}

.margin-right-xxs {
  margin-right: var(--margin-xxs);
}
.margin-right-xxxs {
  margin-right: var(--margin-xxxs);
}

/* margin none */
.margin-none {
  margin: 0;
}
.margin-top-none {
  margin-top: 0;
}
.margin-bottom-none {
  margin-bottom: 0;
}
.margin-right-none {
  margin-right: 0;
}
.margin-left-none {
  margin-left: 0;
}

/* padding none */
.padding-none {
  padding: 0 !important;
}
.padding-top-none {
  padding-top: 0;
}
.padding-bottom-none {
  padding-bottom: 0;
}
.padding-right-none {
  padding-right: 0;
}
.padding-left-none {
  padding-left: 0;
}
/* padding */
.padding-extra-large {
  padding: var(--padding-extra-large);
}

.padding-xxxl {
  padding: var(--padding-xxxl);
}

.padding-xxl {
  padding: var(--padding-xxl);
}

.padding-xl {
  padding: var(--padding-xl);
}

.padding-lg {
  padding: var(--padding-lg);
}

.padding-md {
  padding: var(--padding-md);
}

.padding-sm {
  padding: var(--padding-sm);
}

.padding-xs {
  padding: var(--padding-xs);
}

.padding-xxs {
  padding: var(--padding-xxs);
}
.padding-xxxs {
  padding: var(--padding-xxxs);
}
