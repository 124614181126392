@import "./styles/typography.css";
@import "./styles/colors.css";
@import "./styles/spaces.css";

body {
  font-family: "Roboto", sans-serif;
  background-color: #f7f7f7;
}
.page-loading-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.font-size-sm {
  font-size: 14px;
}
.font-size-md {
  font-size: 16px;
}
.font-size-lg {
  font-size: 18px;
}
.is-link {
  color: #4681f4;
}
.is-link:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: var(--color-primary);
}
.cursor-pointer:hover {
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.full-height {
  height: 100%;
}
/* FLEX styles */
.flex-vertical {
  display: flex;
  flex-direction: column;
}
.flex-container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.display-inline {
  display: inline-block;
}
/* GLOBAL */
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 4px 0;
}
.ant-table-wrapper .ant-table {
  border-radius: 8px;
  overflow: hidden;
}
span.ant-input,
.ant-input-affix-wrapper,
.ant-input-password {
  height: 40px;
}
.ant-btn,
.ant-tag {
  border-radius: 8px;
}
.ant-btn.ant-btn-icon-only.filters-button {
  padding-inline-start: unset;
  padding-inline-end: unset;
  padding: 14px 24px;
}
.ant-input[disabled] {
  background-color: rgba(242, 246, 250, 0.3);
  color: var(--color-gray);
}
.ant-dropdown-menu-item-icon {
  font-size: var(--font-lg) !important;
}
.ant-tabs-nav::before {
  border: 2px solid #d7d7d7;
}
.ant-tabs-ink-bar {
  margin-bottom: 2px;
  height: 4px !important;
  border-radius: 100px 100px 0px 0px;
}

.ant-collapse-header {
  align-items: center;
}
.ant-card,
.ant-card-bordered {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
}
.ant-card .ant-card-meta-description {
  color: var(--color-gray);
}
.ant-divider {
  border-block-start: 1px solid #d7d7d7;
}
.list-collapsable-item {
  width: 100%;
  word-break: break-word;
}
.full-width {
  width: 100%;
}
.max-width-unset {
  max-width: unset;
}
.border-none {
  border: none;
}
.row-reverse {
  flex-direction: row-reverse;
}
p,
.ant-typography p {
  margin: 0;
}
.forms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}
.forms-container .card-container {
  border-bottom: 2px solid #f04134 !important;
}
.forms-container .card-container > div {
  margin: unset;
  padding: 16px;
}
.forms-container .card-container .icon-with-text .text {
  color: #222;
}
.forms-container .modal-custom-footer {
  margin-top: 24px;
}
.ant-notification.ant-notification-topRight {
  margin-top: 20px;
  margin-right: 52px;
}
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  width: max-content;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  margin-bottom: 0;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-message {
  font-size: 14px;
}
.ant-tag {
  margin-inline-end: 0 !important;
}
@media (max-width: 768px) {
  .display-none-tablet {
    display: none;
  }
  .mobile-mode-text-center {
    text-align: center;
  }
  main > * {
    margin: 0 auto;
    max-width: 500px;
    /* background-color: #4681f4; */
  }
  .forms-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  .ant-notification.ant-notification-topRight {
    margin-right: 24px;
    margin-top: 48px;
  }
  .ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
    width: 384px;
  }
}

/* Global Styles for Scrollbars */
/* WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
  width: 3px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #d7d7d7;
}

/* * {
  scrollbar-width: none;
} */

/* *:not(input) {
  scrollbar-color: #d7d7d7 #f0f0f0;
}
.scrollable-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollable-container::-webkit-scrollbar {
  display: none;
} */
