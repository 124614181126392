.payment-methods-container {
  max-width: 400px;
  margin: 0 auto;
}
.payment-item {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
}
.payment-item-selected {
  background-color: #ecf0ff;
}
.image-wrapper {
  width: 70px;
  height: 50px;
}
.exp-date-wrapper > div {
  flex: 1;
  margin-bottom: 0;
}
.add-card-modal-container {
  max-width: 485px;
}
.add-card-wrapper {
  margin-left: 38px;
}
.add-new-card-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #f6f8ff;
}
.list-item {
  padding: 16px;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 8px;
  background-color: #fff;
}
.scrollable-container-transactions {
  height: calc(100vh - 440px);
  overflow: auto;
}

.filters-section-container .ant-picker-input {
  max-width: 74px;
}
.filters-group {
  width: 100%;
  justify-content: flex-end;
}
.filters-button {
  border-color: #d7d7d7;
  padding: 14px 16px;
}
.badge-dot.ant-badge .ant-badge-dot {
  height: 12px;
  width: 12px;
}
.modal-form-container .radio-group > div {
  margin-bottom: 10px;
}
.filter-dropdown-button-wrapper {
  width: 153px;
}
.errors-list {
  margin: 0;
  padding: 0 28px;
}
.transactions-filters-container {
  max-width: fit-content;
  margin-left: auto;
}
.transactions-filters.filters-container {
  inset: 250px 362px auto auto !important;
}
.filter-badge-wrapper {
  position: relative;
}
.date-form-container {
  height: 485px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.date-filter-range-picker {
  visibility: hidden;
  inset: auto auto 146px 20px !important;
  padding: 0;
}
.calendar-icon {
  font-size: 20px;
}
@media (max-width: 900px) {
  .transactions-filters.filters-container {
    inset: 250px 250px auto auto !important;
  }
}
@media (max-width: 800px) {
  .transactions-filters.filters-container {
    inset: 250px 180px auto auto !important;
  }
}
@media (max-width: 768px) {
  .ant-picker-dropdown .ant-picker-date-panel {
    width: 100%;
  }
  .ant-picker-panel-container .ant-picker-content {
    width: 100% !important;
  }
  .ant-picker-date-panel {
    width: 100%;
    align-self: center;
  }
  .ant-picker-dropdown {
    width: calc(100vw - 40px);
  }
}

@media (max-width: 768px) {
  .main-scrollable-container {
    height: calc(100vh - 294px);
    overflow: auto;
  }
  .transactions-filters-container {
    margin-left: 0;
  }
  .filters-section-container {
    flex-direction: column;
    gap: 8px;
  }
  .filters-group {
    justify-content: space-between;
  }
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
  .exp-date-wrapper {
    gap: 12px !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-inline-end: 0;
  }
}
