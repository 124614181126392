:root {
  --color-black: #222222;
  --color-white: #ffffff;
  --color-gray: #7a7a7a;
  --color-success: #01a601;
  --color-error: #f04134;
  --color-primary: #4d6ef2;
}

.text-black {
  color: var(--color-black);
}

.text-white {
  color: var(--color-white);
}

.text-gray {
  color: var(--color-gray);
}

.text-success {
  color: var(--color-success);
}

.text-error {
  color: var(--color-error);
}

.text-inactive {
  color: rgba(0, 0, 0, 0.45);
}

.text-primary {
  color: var(--color-primary);
}
