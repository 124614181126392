.collapsable-item-content-wrapper {
  padding: 24px;
  margin-top: 24px;
  background-color: #ffffff;
}
.scrollable-container-contractors .ant-collapse-header,
.scrollable-container-contractors .ant-collapse-content-box {
  padding: 0 !important;
}
.contractor-list-item-wrapper {
  padding: 4px 0 !important;
}
.scrollable-container-contractors {
  height: calc(100vh - 450px);
}