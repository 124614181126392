.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 103px;
}
.statuses-container {
  height: 32px;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #d7d7d7;
  background: #fff;
  min-width: 110px;
  justify-content: center;
}
