.general-info-container {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background: #fff;
  padding: 12px 16px;
}
.info-divider {
  height: auto;
  align-self: stretch;
  margin: 10px 32px;
}
@media (max-width: 768px) {
  .general-info-container > div {
    width: 100%;
  }

  .info-item {
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    align-items: unset;
  }
  .info-divider {
    margin: 16px 0;
  }
}
