.info-field-name {
  margin-right: 18px;
}
.info-field-value {
  word-break: break-word;
  flex: 1;
}
/* new info field stylex */
.info-field-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
}

.info-item {
  display: flex;
  gap: 20px;
}
.info-field {
  display: flex;
  flex:1;
  word-break: break-word;
}
.info-value {
  word-break: break-word;
  flex:1
}
/* for dynamic counting widths ===> 
.info-field {
  flex: 0 0 auto;
  max-width: 160px;
  margin-right: 20px;
}

.info-value {
  flex: 2;
  word-break: break-word;
} */
