.header-navigation {
  width: 100%;
  margin-left: 100px;
  background-color: inherit;
  border-bottom: none;
  display: flex;
  gap: 12px;
}
.header-navigation-vertical {
  max-width: fit-content;
  border: none !important;
}
.menu-icon {
  font-size: 16px;
  vertical-align: middle;
}
.menu-drawer,
.menu-drawer .ant-menu-dark {
  background-color: #272534 !important;
}
.menu-drawer .ant-menu-item {
  width: calc(100vw - 48px);
}
.ant-drawer-close {
  margin-left: auto !important;
  /* margin-inline-end: 0 !important; */
  margin-top: 6px;
}
.ant-drawer-close svg {
  color: #fff;
}

.page-main-menu {
  padding-left: 15px;
  padding-right: 11px;
  border-right: 0;
  background: #272534;
}
.page-main-menu ul,
.page-main-menu div > ul > li.ant-menu-item:not(.ant-menu-item-selected) {
  background: transparent !important;
}
.page-main-menu .ant-menu-item.ant-menu-item-only-child {
  margin: 4px 0;
}
.page-main-menu .ant-menu-submenu-title,
.page-main-menu .ant-menu-item {
  padding-left: 16px !important;
}
li.ant-menu-item.ant-menu-item-only-child {
  padding-left: 40px !important;
}
.page-main-menu .ant-menu-item-selected.ant-menu-item-only-child {
  background-color: var(--color-primary);
  border-radius: 8px;
}
.menu-drawer .ant-drawer-header.ant-drawer-header-close-only {
  padding: 16px 16px 0;
}
.menu-drawer .ant-drawer-body {
  padding-top: 0;
}
@media (max-width: 768px) {
  .page-main-menu {
    padding-left: 0;
    padding-right: 0;
  }
}
