.upload-container > .ant-upload-drag {
  background: transparent;
  border-color: var(--color-gray);
}
.ant-upload-list-item-actions,
.ant-upload-list-item-action {
  opacity: 1 !important;
  margin-left: auto;
  text-align: center !important;
}
.ant-upload-list-item-action:hover {
  text-align: center !important;
}
.ant-upload-list-item-name {
  text-align: left;
}

.ant-upload-list {
  border-radius: 8px;
  margin-top: 12px;
  max-height: 200px;
  overflow: auto;
}
.ant-upload-list-item {
  background: #ffffff;
  padding: 24px 16px;
  max-height: 270px;
  margin-top: 0 !important;
}

.delete-all-button {
  background-color: #ffffff;
  border-top: 1px solid #d7d7d7;
  padding: 16px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-bottom: 16px;
}
.delete-icon {
  font-size: 22px !important;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  padding: 9px;
}
.multiple-upload-container .ant-upload-list {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.upload {
  position: relative;
}
.upload .ant-upload-list.ant-upload-list-text {
  margin-top: 36px;
}
.upload .anticon-delete,
.upload .ant-upload-icon {
  font-size: 16px;
}
.uploading-file {
  padding: 14px 24px;
  border-top: 1px solid #d7d7d7;
}
.upload .ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.88) !important;
  cursor: unset;
}
.files-max-size {
  position: absolute;
  z-index: 11;
  top: 50px;
}
