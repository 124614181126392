:root {
  --font-xxxl: 32px;
  --font-xxl: 28px;
  --font-xl: 24px;
  --font-lg: 18px;
  --font-md: 16px;
  --font-sm: 14px;
  --font-xs: 12px;
  --font-xxs: 11px;
  --font-bold: 600;
  --font-semibold-light: 400;
  --font-semibold: 500;
}

.text-xxxl {
  font-size: var(--font-xxxl);
}

.text-xxl {
  font-size: var(--font-xxl);
}

.text-xl {
  font-size: var(--font-xl);
}

.text-lg {
  font-size: var(--font-lg);
}

.text-md {
  font-size: var(--font-md);
}

.text-sm {
  font-size: var(--font-sm);
}

.text-xs {
  font-size: var(--font-xs);
}

.text-xxs {
  font-size: var(--font-xxs);
}

.text-bold {
  font-weight: var(--font-bold);
}

.text-semibold {
  font-weight: var(--font-semibold);
}
.text-semibold-light {
  font-weight: var(--font-semibold-light);
}