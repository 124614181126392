.no-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-company-info-container {
  max-height: 280px;
  width: 400px;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
}
.date-wrapper {
  margin-top: 76px;
}
.deactivate-text {
  color: #b3261e;
}
.form-wrapper {
  padding: 0;
}
.info-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-content-wrapper .info-logo {
  width: 120px;
  height: 120px;
  align-self: center;
  font-size: 70px;
  justify-content: center;
  padding: 4px;
  overflow: hidden;
  margin: 16px 0 0;
}
.profile-content-wrapper .info-logo.info-logo-icon {
  object-fit: contain;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  padding: 4px;
}
.profile-content-wrapper .info-logo img {
  object-fit: contain;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  padding: 4px;
}
.info-logo-placeholder {
  width: 120px;
  height: 120px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
}
.logo-upload-container .ant-upload {
  border-color: var(--color-primary) !important;
  border-width: 2px !important;
}
.logo-upload {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  padding: 4px;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  background-color: #fff;
  padding: 4px;
}
.logo-upload-icon {
  font-size: 24px;
  color: #4681f4;
}
.logo-info-text {
  max-width: 230px;
  text-align: center;
  margin: 0 auto;
}

.profile-content-wrapper .card-container {
  width: 400px;
  max-width: 400px;
}
.profile-content-wrapper .card-container.profile-password-card .ant-card-body {
  margin: auto;
}
.profile-password-card {
  height: 280px;
}
@media (max-width: 768px) {
  .profile-content-wrapper .card-container,
  .no-company-info-container {
    width: 100%;
  }
  .profile-content {
    flex-direction: column;
  }
  .profile-content-wrapper .profile-content-child > div {
    height: unset;
  }
}
