.scrollable-list-item-wrapper .ant-list-item {
  padding: 4px 0;
}
/*code dublication contractors/styles.css */
.scrollable-container-revisions {
  height: calc(100vh - 450px);
  overflow: auto;
}
.scrollable-container-revisions .ant-collapse-header,
.scrollable-container-revisions .ant-collapse-content-box {
  padding: 0 !important;
}
