.page-header {
  /* display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  z-index: 1;
  height: 64px;
  width: 100%;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  background: #f1f4ff;
  margin-bottom: 3px; */
}

.page-header-auth {
  /* display: flex;
  align-items: center;
  padding: 20px 40px;
  z-index: 1;
  margin-bottom: 3px; */
  height: 60px;
  background: #f2f6fa;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

/* .page-header-dropdown-icon {
  color: #272534;
} */

@media (max-width: 768px) {
  .page-header {
    justify-content: space-between;
    padding: 13px 30px;
  }
  .page-header-auth {
    /* uncomment after auth header menu enable 
    /* flex-direction: row-reverse; */
    justify-content: center;
    align-items: center;
  }
}
