.filters-container {
  inset: 153px 52px auto auto !important;
}
.filters-container
  li.ant-dropdown-menu-item
  .ant-dropdown-menu-title-content
  > * {
  padding: 11px 70px 11px 14px !important;
}
.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--color-primary) !important;
}
