.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.solar-steps {
  padding: 0 24px;
}
.steps-title {
  font-size: 14px;
}
.steps-form {
  /* max-width: -webkit-fill-available; */
  text-align: left;
}
.form-content {
  width: 100%;
}
.form-content .ant-upload-list-item-action {
  text-align: right;
}
.ant-steps .ant-steps-item-title {
  line-height: 20px;
}
.roof-image {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  overflow: hidden;
}
.solar-steps .ant-steps-item-container > .ant-steps-item-content {
  display: none;
}
.solar-steps .ant-steps-item-icon {
  margin-inline-start: 0 !important;
}
.solar-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 12px !important;
  top: 14px !important;
}
.solar-steps.ant-steps .ant-steps-item-tail::after {
  height: 2px;
}
.compact-switch.ant-switch {
  background-color: var(--color-primary);
}
@media (max-width: 768px) {
  .buttons-container {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
  .solar-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
    top: 9px !important;
  }
  .solar-steps {
    padding: 0;
  }
}
@media (max-width: 530px) {
  .ant-upload-list-item-name {
    max-width: 250px;
  }
}
