.result-wrapper {
  border-radius: var(--Corner-radius, 8px);
  background-color: #fff;
  margin-bottom: 5px;
  border-bottom: 4px solid var(--color-primary) !important;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.result-wrapper.success {
  border-bottom: 4px solid #01a601 !important;
}
.result-wrapper.error {
  border-bottom: 4px solid #f04134 !important;
}
.result-wrapper .button {
  display: inline-block;
}
@media (max-width: 768px) {
  .result-wrapper .button {
    width: 100%;
  }
}
