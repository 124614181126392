.auth-info-container {
  height: 100vh;
  font-size: 32px;
  font-weight: 500;
  background-image: url("../../components/images/auth-background.png");
  background-color: #272534;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-forms-section-content {
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
}
.auth-info-content {
  height: calc(100% - 36px);
  max-width: 444px;
  margin: 0 auto;
}
.auth-logo-wrapper {
  margin-bottom: 120px;
}
.by-gmail-dividers-wrapper {
  display: flex;
  align-items: center;
  margin: 12px 0;
}
.text-or {
  margin: 0 12px;
}
.custom-divider {
  height: 1px;
  width: 100%;
  background-color: #22222266;
}
.button {
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.privacy-checkbox {
  margin-right: 12px;
}
.agreement-wrapper {
  max-width: 900px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
}
.paragraph-title {
  font-style: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 14px;
}
.login-form .card-container {
  border-bottom: 2px solid #f04134 !important;
}
.login-form .card-container > div {
  margin: unset;
  padding: 16px;
}
.login-form .card-container .icon-with-text .text {
  color: #222;
}
@media (max-width: 768px) {
  .auth-forms-section-content {
    padding: 30px 0;
  }
}
@media (max-width: 414px) {
  .auth-forms-section-content {
    max-width: 380px;
  }
}
@media (max-width: 390px) {
  .auth-forms-section-content {
    max-width: 340px;
  }
}
