.table-container {
  overflow: auto;
}

.table-header {
  font-weight: 500;
  margin-bottom: 12px;
  background-color: #f0f0f0;
  width: max-content;
  min-width: 100%;
}
.scrollable-container {
  /* width: max-content; */
  min-width: 100%;
}
.table-list-item {
  padding: 16px;
  border-radius: 8px;
}
.small-col {
  width: unset !important;
  min-width: fit-content;
}
.col-item-data-wrapper .ant-typography {
  display: block;
  width: max-content;
  max-width: 250px;
  word-break: break-word;
}
@media (max-width: 768px) {
  .table-list-item {
    padding: 14px 24px;
  }
}
/* code dublication */
.scrollable-container {
  height: calc(100vh - 330px);
  overflow: auto;
}
.scrollable-container .ant-collapse-header,
.scrollable-container .ant-collapse-content-box {
  padding: 0 !important;
}
