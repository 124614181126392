.form-input {
  height: 40px;
  border-radius: 8px;
  padding: 4px 24px;
}
.input-textarea > .ant-input {
  background-color: transparent;
  padding: 4px 16px;
}
.ant-input-data-count {
  color: var(--color-gray) !important;
}
.form-select {
  height: 40px;
}
.form-select .ant-select-selector {
  border-radius: 8px;
  border-color: var(--color-gray) !important;
  padding: 8px 16px !important;
  background-color: transparent !important;
}
.form-select-error .ant-select-selector {
  border: 1px solid #f04134 !important;
}
.form-control::placeholder {
  color: #999;
  opacity: 0.6;
}
.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag {
  border-radius: 8px 0 0 8px !important;
}
.react-tel-input .selected-flag {
  padding: 0 0 0 11px;
}
.react-tel-input .flag-dropdown:hover {
  border-radius: 8px 0 0 8px !important;
}
.ant-form-item-explain {
  margin-bottom: 8px;
}
.add-new-item-divider {
  margin: 8px 0;
}
.add-new-item-wrapper {
  padding: 0 8px 4px;
}
.input-textarea {
  min-height: 70px;
  background-color: transparent;
}
.ant-form-item {
  margin-bottom: 0;
}
.ant-form-item-explain {
  display: none;
}
.react-multi-email {
  border-radius: 8px !important;
}
.react-multi-email > .data-labels > div {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray);
  border-radius: 8px;
  background-color: #f6f8ff !important;
  border-radius: 8px;
}
.react-multi-email > .data-labels > div:first-child {
  margin-left: 0.14285714em !important;
}
.search-input {
  max-width: 250px;
}
.search-icon {
  font-size: 20px;
  color: var(--color-gray);
  margin: 0 12px;
}
.custom-floating-input.mdc-text-field--outlined {
  height: 40px;
  max-width: 400px;
}
/* Floating input */
.floating-wrapper {
  margin-bottom: 16px;
}
.floating-wrapper .form-item-error {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.floating-wrapper .form-item-error.show {
  opacity: 1;
}
.input_container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.label {
  line-height: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s ease;
  pointer-events: none;
  color: var(--color-gray);
  z-index: 10;
}
.textarea-label {
  line-height: 16px;
  position: absolute;
  left: 16px;
  top: 16px;
  transform: translateY(-50%);
  transition: all 0.25s ease;
  pointer-events: none;
  color: var(--color-gray);
  z-index: 10;
}
.form-item {
  margin-bottom: 0;
}
.input-textarea {
  height: 170px;
}
.input {
  border-radius: 8px;
  border-color: var(--color-gray);
  padding: 8px 16px;
  background-color: transparent;
}
.input-error {
  border-color:#f04134;
}
.search-input input::placeholder {
  color: var(--color-gray);
}
.input:focus,
.input:hover,
.input .ant-input:focus,
.input .ant-input:hover {
  border-color: var(--color-gray) !important;
}
@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}
.input:-webkit-autofill,
.form-select:-webkit-autofill {
  font-size: 14px;
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

.input:not(:-webkit-autofill),
.form-select:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
.hide {
  top: 2px;
  left: 16px;
  font-size: 12px;
  pointer-events: all;
  background-color: #f7f7f7;
  padding: 0 1px;
  z-index: 11;
}
.custom-suffix {
  position: absolute;
  right: 16px;
  top: 9px;
}
.creatable-select,
.multi-email-container {
  position: relative;
}
.creatable-select .ant-select-selector {
  padding-left: 40px;
  min-height: 40px;
  border-radius: 8px;
}
.creatable-select-form-item {
  margin-bottom: 0 !important;
}
.multi-email.react-multi-email {
  padding-left: 40px;
}
@media (max-width: 768px) {
  .search-input {
    max-width: unset;
  }
  .input-textarea .ant-input-data-count {
    display: none;
  }
}
