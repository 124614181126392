.card-container {
  overflow: hidden;
  border-radius: 8px;
  border-bottom: 2px solid var(--color-primary) !important;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  background: #fff;
  width: 100%;
}
.card-container .ant-card-body {
  padding: 0;
}
.card-container .main-info {
  padding: 16px 24px;
  background-color: #f1f4ff;
}
.card-container .ant-card-actions > li {
  padding: 11px !important;
}
.card-container {
  display: flex;
  flex-direction: column;
}
